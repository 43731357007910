@import '_color-variables.scss';

#glossary-container {
  margin: 0 auto 8rem;
  overflow: hidden;

  .mh2-negative {
    margin-left:  -0.75rem;
    margin-right: -0.75rem;
  }

  .background-container {
    max-width: 1280px;
  }

  .glossary-content-button {
    margin: 5px 0;
    width: 100%;
    background-color: $charcoal;
    text-transform: none;
    transition: background-color 0.3s ease;
  }

  .full-fixed-height {
    max-height: 75vh;
    overflow-y: auto;
  }

  .full-fixed-height{
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  /* Icon for glossary popup
  ----------------------------------------------*/

  i.small {
    color:$charcoal;
  }
}

.glossary {
  .glossary__search {
    line-height: 160%;
    cursor: text;
    background-color: transparent;
    border: 1px solid $grey;
    border-radius: 3px;
    height: 3rem;
    width: 100%;
    max-width: 21.44rem;
    font-size: 1rem;
    padding: 0;
    padding-left: 1rem;
    transition: all 0.3s;
  }

  .glossary__search:focus {
    border: 1px solid $teal;
    box-shadow: 0 1px 0 0 $teal;
  }

  .glossary__detail {
    margin-top: 1em;
    margin-right: 3em;
  }

  .glossary__title {
    color: $teal;
    font-size: 1.125rem;
    line-height: 110%;
    margin: 0 0 1rem 0;
  }

  a.glossary__anchor {
    display: block;
    position: relative;
    top: -10rem;
    visibility: hidden;
  }

  .glossary__header {
    display: block;
    margin: 0;
    font-weight: 600;
    font-size: 1em;
    color: $charcoal;
  }

  .glossary__description {
    display: block;
    color: $charcoal;
    font-weight: 500;
    line-height: 160%;

    p {
      margin: 1rem 0;
    }

    a {
      color: $dark-teal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.6;
      cursor: pointer;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .glossary__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .glossary__filters {

    .glossary__filter {
      color: $charcoal;
      background-color: transparent;
      border: none;
      border-right: 1px solid $grey;
      text-decoration: underline;
      cursor: pointer;
      padding: 0rem 0.5rem;
      margin: 0.5rem 0rem;

      &:first {
        padding: 0rem 0.5rem 0rem 0rem;
      }
    }

    .glossary__filter:hover {
      color: $teal;
    }

    .glossary__filter--active {
      color: $teal;
      text-decoration: none;
      font-weight: bold;
    }
  }
}

.definition-term__link {
  font-family: "Open Sans", sans-serif;
  color: #0095ac;
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

@media #{$breakpoint-large} {
  .glossary {
    .glossary__detail {
      width: 50%;
    }
    .glossary__info {
      max-width: 40rem;
      display: flex;
    }
  }
}